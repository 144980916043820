import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
// styles
import clsx from 'clsx';
import itemStyles from '../components/item/cases.module.css';
import styles from './beyond-green.module.css';
// components
import Layout from '../components/item/layout';
import Team from '../components/team';
import Resume from '../components/resume';
// other
import { meta } from '../../../metaData';
import Blockquote from '../components/blockquote';

const BeyondGreenCase = () => {
  const data = useStaticQuery(graphql`
    query {
      GregChristian: file(relativePath: { eq: "GregChristian.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen1: file(relativePath: { eq: "beyond-green-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1980, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen2: file(relativePath: { eq: "beyond-green-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1980, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen3: file(relativePath: { eq: "beyond-green-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 1980, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen4: file(relativePath: { eq: "beyond-green-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 1980, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen5: file(relativePath: { eq: "beyond-green-5.png" }) {
        childImageSharp {
          fluid(maxWidth: 1980, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen6: file(relativePath: { eq: "beyond-green-6.png" }) {
        childImageSharp {
          fluid(maxWidth: 1980, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout
      isRed
      score={['Foodtech', 'USA', '2020', 'Ongoing']}
      navBtn="#14181A"
      headerTitle="Beyond Green Food Partners: Feature-rich Customized Menu Ordering Application"
      subTitle="How Brocoders developed a client-oriented online ordering system to help Beyond Green Partners streamline their internal management processes and enable accurate food delivery. "
      className={styles.header}
      contentClassName={styles.content}
      breadcrumbsClassName={styles.breadcrumbs}
      metaData={meta.beyondGreen}
    >
      <div className={itemStyles.inner}>
        <p className={itemStyles.preamble}>
          Beyond Green Partners is a foodservice provider for schools in Chicago, IL. Powered by a
          custom multi-functioning software solution, every school customer can review and order
          menu items in a clear, concise way while the food provider can enjoy a powerful management
          tool.
        </p>
        <h2 className={itemStyles.title}>Our client</h2>
        <p className={itemStyles.paragraph}>
          Beyond Green Sustainable Food Partners is a Chicago-based school lunch provider that is
          mainly focused on cooking and delivering quality food to children in schools and childcare
          centers. Driven by a disruptive idea to expand their digital footprint, they tend to
          continuously improve the service quality, thus drawing new customers and partners.
        </p>
        <p className={itemStyles.paragraph}>
          Brocoders established a dynamic business relationship with the Beyond Green Sustainable
          Food Partners team. Thanks to a collaboration with the Greg Christian, founder and CEO,
          Heidi Ames, partner manager, and Ford Bruton, a foodservice coordinator, we succeeded in
          creating an innovative online foodservice management platform.
        </p>
        <h2 className={itemStyles.title}>The situation</h2>
        <p className={itemStyles.paragraph}>
          Before Brocoders came into play, the client had utilized an online order service that was
          built on legacy technologies. It successfully dealt with the business challenges on a
          small scale, but it was about time to migrate to an up-to-date solution.
        </p>
        <p className={itemStyles.paragraph}>
          The preceding ordering platform had certain functional limitations, and it was impossible
          to implement new features on its outdated engine that the system was based on. Besides, it
          was critically important for our client to receive in-depth business information, but most
          of the extracted financial reports showed incorrect data.
        </p>
        <p className={itemStyles.paragraph}>
          That said, the Beyond Green Sustainable Food Partners team decided to develop a robust
          tech-enabled solution with extended functionality that would allow them to get rid of the
          previous issues, as well as to implement modifications and next-version function changes.
        </p>
        <p className={itemStyles.paragraph}>
          Beyond Green Partners searched for a reliable tech partner that could entirely fulfill the
          task in a timely manner and aid its team in building and supporting a stable, fail-free
          system.
        </p>
        <p className={itemStyles.paragraph}>
          There were a few interviews with several companies, but Brocoders won the client’s trust
          and was chosen as a business partner. From the start, we performed thorough research of
          the client’s business processes and developed a prototype based on technical
          specifications.
        </p>
        <p className={itemStyles.paragraph}>
          Our team was tasked to renovate the entire legacy solution and create a cutting-edge
          platform empowered with the following subsystems:
        </p>
        <ul className={clsx(itemStyles.bullets, styles.bulletsColor)}>
          <li>Order management system</li>
          <li>Customer management system</li>
          <li>Reporting system</li>
        </ul>
      </div>
      <Img fluid={data.screen1.childImageSharp.fluid} className={itemStyles.container} alt="" />
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>Working process</h2>
        <p className={itemStyles.paragraph}>
          To overcome the challenges above and push on to the next level, we started with the
          research and requirements elicitation. Our primary goal at this stage was to align all the
          crucial details and define the development strategy, along with further project stages. It
          was decided to start with workshops, during which our partner provided us with his designs
          and visions of the final product.
        </p>
        <p className={itemStyles.paragraph}>
          Based on previous experience, the Beyond Green Partners wanted to control and keep track
          of progress at regular intervals. The scope of the project was pervasive, with many
          changes expected along the way.
        </p>
        <p className={itemStyles.paragraph}>
          That said, we employed the iterative development methodology to enable easier testing and
          debugging, as well as to ensure the flexibility to changes throughout the entire
          development period. Furthermore, we conducted weekly meetings with the client to maximize
          our efforts and eliminate any emerging issues early on.
        </p>
      </div>
      <Img
        fluid={data.screen2.childImageSharp.fluid}
        className={itemStyles.container}
        style={{ marginBottom: '-15%', marginTop: '-7%' }}
        alt=""
      />
      <div className={itemStyles.inner}>
        <p className={itemStyles.paragraph}>
          After requirements elaboration, we moved on to building an MVP from scratch that could
          later be transformed into a fully-functioning platform. To maximize the outcomes, we used
          React.js for the frontend and Node.js for the backend.
        </p>
        <p className={itemStyles.paragraph}>
          According to one of the most vital tasks set before us by the client, we had to create an
          eye-pleasing and user-friendly web app with an intuitive interface so that every user
          could clearly understand where to go or how to execute orders. To ensure we are on the
          same page with the client, we created a prototype driven by our best practices.
        </p>
        <p className={itemStyles.paragraph}>
          Thanks to the iterative development methodology, we managed to meet tight deadlines and
          enable seamless improvements proposed by the client from time to time. We also provided
          the client with small increments all along the road to acquire relevant feedback and show
          the work done.
        </p>
      </div>
      <Img fluid={data.screen3.childImageSharp.fluid} className={itemStyles.container} alt="" />
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>Business challenges</h2>
        <p className={itemStyles.paragraph}>
          From the very beginning of this project, we decided to stick
          <Link className={styles.link} to="/blog/administration_panels_comparison/">
            {' '}
            to a React-based Admin Panel
          </Link>{' '}
          that allowed us to boost the overall development speed as of embedded out-of-the-box
          features.
        </p>
        <p className={itemStyles.paragraph}>
          Thanks to out-of-the-box functionality, we were able to carry out a lot of tasks quickly.
          On the flip side of the coin, such tools might have some pitfalls and limitations so that
          software developers have to write custom logic and additional blocks all by themselves.
        </p>
        <ul className={clsx(itemStyles.bullets, styles.bulletsColor)}>
          <li>
            In light of this, we faced the issue caused by the daily menu creation and editing
            processes. The problem lay within the admin panel that simply couldn’t “understand” qa
            transition logic each time an item was created or edited. Nevertheless, we managed to
            fix the issue by rewriting the admin panel’s logic and adapting it to the project’s
            architecture.
          </li>
          <li>
            There was another problem regarding the calendar integration. Since the calendar wasn’t
            attached to the React.js library, we had to find a workaround in order to implement this
            feature. That said, we managed to find a more stable calendar release that was
            integrated into the Beyond Green Partners 'solution eventually.
          </li>
        </ul>
      </div>
      <Img fluid={data.screen4.childImageSharp.fluid} className={itemStyles.container} alt="" />
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>Our team</h2>
        <p className={itemStyles.paragraph}>
          The project’s tight release date put an additional strain on our team. To avoid multiple
          risks and simplify the development process, we assembled a team of self-motivated seasoned
          experts. They demonstrated their willingness to go the extra mile and take on additional
          responsibilities while simultaneously meeting all the project’s deadlines.
        </p>
        <p className={itemStyles.paragraph}>
          The Brocoders team that proved its skill to smoothly handle this project under harsh
          timelines was comprised of:
        </p>
        <Team
          color="#82AA00"
          list={[
            { count: 1, title: 'Project\nmanager' },
            { count: 2, title: 'Backend\nEngineers' },
            { count: 1, title: 'Frontend\nEngineer' },
            { count: 1, title: 'UI/UX\ndesigner' },
            { count: 1, title: 'Business\nanalyst' },
            { count: 1, title: 'QA\nEngineer' },
          ]}
        />
        <Resume services={['digital']} technology={['react', 'nodejs', 'figma']} />
      </div>
      <Blockquote
        img={data.GregChristian.childImageSharp.fluid}
        text='"When we discussed our ideas, Brocoders reminded us to keep it simple. Usually, development companies want to work on complex projects. Brocoders’ leadership team told us to keep the product simple, which made it better. They also never guessed what we needed. All the other agencies that we interviewed took big chances and told us what they thought we needed. They’re a team of authentic listeners."'
        downloadReviewLink="https://bit.ly/3DA3tMz"
        author={
          <React.Fragment>
            <b>Greg Christian</b>
            <br />
            CEO at Beyond Green
          </React.Fragment>
        }
      />
      <Img fluid={data.screen5.childImageSharp.fluid} className={styles.screen5} alt="" />
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>Delivered product</h2>
        <p className={itemStyles.paragraph}>
          By relying on a fruitful mutual collaboration with the client, Brocoders developed an
          innovative, secure, fast and easy-to-use online platform for managing school lunch orders.
        </p>
        <p className={itemStyles.paragraph}>
          The solution features all the required functionality and provides all the necessary means
          for controlling and monitoring a complete food delivery cycle. Meanwhile, the rock-solid
          security algorithms ensure all data is safe and sound and remain confidential.
        </p>
        <p className={itemStyles.paragraph}>
          First and foremost, the app is mainly designed for the following users:
        </p>
        <ul className={clsx(itemStyles.bullets, styles.bulletsColor)}>
          <li>School Food Service Managers</li>
          <li>The Beyond Green Partners admins</li>
        </ul>
        <p className={itemStyles.paragraph}>
          From the end-user side, school food service managers acquired a school dashboard designed
          to place orders for classrooms and manage orders via an in-built calendar feature.
        </p>
        <p className={itemStyles.paragraph}>
          School food service managers came up with the following functionality:
        </p>
        <ul className={clsx(itemStyles.bullets, styles.bulletsColor)}>
          <li>Create or delete classrooms</li>
          <li>View and manage scheduled orders in the calendar section</li>
          <li>
            Place new orders and check the order status. Enter a portion number for items needed to
            be ordered
          </li>
          <li>
            Add comments to each order (to specify additional requirements, e.g., to note that some
            students might have allergies to specific ingredients)
          </li>
        </ul>
        <p className={itemStyles.paragraph}>
          The user-friendly admin panel, designed to be utilized by the Beyond Green Partners team,
          includes the following key blocks:
        </p>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Key blocks (models)</th>
              <th>Admins can</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p className={itemStyles.paragraph}>
                  The Customer is a school that will order a menu
                </p>
              </td>
              <td>
                <p className={itemStyles.paragraph}>
                  Register new customers and view the list of schools.
                </p>
              </td>
            </tr>

            <tr>
              <td>
                <p className={itemStyles.paragraph}>
                  The Component shows a separate piece of the meal or ingredients (e.g., chicken
                  piece, rice, apple, orange, nuts, potato, buckwheat, etc.)
                </p>
              </td>
              <td>
                <p className={itemStyles.paragraph}>
                  View and edit the ingredients list. Add new ingredients
                </p>
              </td>
            </tr>

            <tr>
              <td>
                <p className={itemStyles.paragraph}>
                  The Meal time is a part of the day when the component can be ordered (e.g.,
                  breakfast, dinner, lunch, etc.)
                </p>
              </td>
              <td>
                <p className={itemStyles.paragraph}>View and edit the meal time section</p>
              </td>
            </tr>

            <tr>
              <td>
                <p className={itemStyles.paragraph}>The Meal item (Menu item)</p>
              </td>
              <td>
                <p className={itemStyles.paragraph}>
                  A constructor tool, where an admin can pick several components and create a daily
                  menu (rice + chicken, potato + salad, and so on)
                </p>
              </td>
            </tr>

            <tr>
              <td>
                <p className={itemStyles.paragraph}>The Daily menu</p>
              </td>
              <td>
                <p className={itemStyles.paragraph}>
                  View and edit all the menus added to the system. Create menus based on the
                  pre-saved meal items to enable schools to choose and book their menu
                </p>
              </td>
            </tr>

            <tr>
              <td>
                <p className={itemStyles.paragraph}>
                  The Category shows the type of the meal item (e.g., vegan, gluten-free)
                </p>
              </td>
              <td>
                <p className={itemStyles.paragraph}>
                  View and edit all the category items that are already in the system. Create new
                  categories, so that it would be easier to notify schools what meals they are going
                  to order
                </p>
              </td>
            </tr>

            <tr>
              <td>
                <p className={itemStyles.paragraph}>The Orders</p>
              </td>
              <td>
                <p className={itemStyles.paragraph}>
                  Manage customer orders in the calendar section and view customer comments. Create
                  menu calendars and edit customer orders, in case a customer made an incorrect
                  order
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <p className={itemStyles.paragraph}>
          Apart from everything else, the Beyond Green Partner admins come up with advanced
          reporting and statistics. The detailed reports cover a great variety of metrics, including
          purchases, delivery schedules, and more. There are four major types of reports:
        </p>

        <p className={clsx(itemStyles.paragraph, styles.bold)}>Financial</p>
        <p className={itemStyles.paragraph}>
          Admins can check how much money each school should pay to quickly send the invoice.
        </p>
        <p className={clsx(itemStyles.paragraph, styles.bold)}>Container Label</p>
        <p className={itemStyles.paragraph}>
          Each container is labeled with a code to ensure full-fledged tracking.
        </p>
        <p className={clsx(itemStyles.paragraph, styles.bold)}>Production</p>
        <p className={itemStyles.paragraph}>
          The client can acquire information on the number of products used for each school or
          classroom.
        </p>
        <p className={clsx(itemStyles.paragraph, styles.bold)}>Delivery</p>
        <p className={itemStyles.paragraph}>
          The Beyond Green Partners team can now analyze invaluable data extracted from delivery
          reports.
        </p>
        <p className={itemStyles.paragraph}>
          Both Beyond Green Partners and their end-customers can enjoy the app’s benefits in order
          to allow all students to receive fresh and healthy food that let them achieve their
          highest potential during their school years.
        </p>
        <p className={itemStyles.paragraph}>
          Our partner can now maintain multiple clients on one platform while minimizing waste
          thanks to the accurate ordering and preparation procedures. On the other hand, schools can
          acquire transparent data on every meal they order, as well as smoothly schedule their
          orders for the months ahead.
        </p>
      </div>
      <Img fluid={data.screen6.childImageSharp.fluid} className={itemStyles.container} alt="" />
      <div className={clsx(itemStyles.inner, itemStyles.lastItem)}>
        <h2 className={itemStyles.title}>The results</h2>
        <p className={itemStyles.paragraph}>
          Backed by a transparent software development process that has been polished throughout
          almost 100 successful projects, Brocoders managed to mitigate numerous risks and fix all
          the issues at a rapid pace.
        </p>
        <p className={itemStyles.paragraph}>
          The project’s development has been going on since the June, 2020 and is nearly completed.
          To date, the project is being prepared for the demo and the commissioning stages.
        </p>
      </div>
    </Layout>
  );
};

export default BeyondGreenCase;
